import { nanoid } from 'nanoid';

// HEAD DATA
export const headData = {
  title: 'Valentin Dedrie - Expert en stratégie marketing et communication & Webdesigner', // e.g: 'Name | Developer'
  lang: 'fr', // e.g: en, es, fr, jp
  description: 'Valentin Dedrie - Freelance dans le monde du marketing, de la communication et du webdesign', // e.g: Welcome to my website
};

// HERO DATA
export const heroData = {
  title: "Salut, je m'appelle",
  name: 'Valentin Dedrie',
  subtitle: 'Expert en stratégie Marketing et Communication & Webdesigner',
  cta: 'En savoir plus',
};

// ABOUT DATA
export const aboutData = {
  img: 'valentin-dedrie.jpg',
  paragraphOne: "Expert en stratégie marketing et communication depuis plus de 5ans. Diplômé d'un Master en stratégie Marketing et Communication chez OpenClassrooms.",
  paragraphTwo: "Je peux gérer vos différentes campagnes de publicités, que ça soit pout Facebook Ads, Twitter Ads, LinkedIn Ads ou encore Snapchat Ads. Plus d'une dizaine de clients me font déjà confiance, pourquoi pas vous ? Gestion des réseaux sociaux et community management de plusieurs clients : commerce, immobilier, sport, avocat, etc... Mes années d'expérience me permettent de m'adapter facilement à tous les secteurs d'activité.",
  paragraphThree: "Réalisation de sites web, je réalise les cahiers des charges de mes différents clients en pensant à leurs contraintes, leur budget, l'UX, l'UI mais aussi le référencement naturel. Suite à la réalisation du cahier des charges, je m'attaque à la partie réalisation du site web. Wordpress, Shopify, Prestashop, ou HTML/CSS, j'adapte la technologie utilisée selon vos différents besoins.",
  resume: '', // if no resume, the button will not show up
};

// PROJECTS DATA
export const projectsData = [
  {
    id: nanoid(),
    img: 'projet-valentin-dedrie-site-colle-a-bois.jpg',
    title: "Site  d'affiliation dédié à la colle à bois",
    info: "Réalisation d'un site/blog dédié à la colle à bois. Plusieurs pages sur les marques de colle à bois (Titebond, Pattex, Gorilla, etc...), les accessoires sur les colles à bois, des conseils pour utiliser de la colle à bois, etc...",
    info2: '',
    url: 'https://colle-a-bois.fr/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'projet-valentin-dedrie-trainly.jpg',
    title: 'Trainly - Identité graphique',
    info: "Réalisation de l'identité graphique ainsi que les différentes maquettes de l'application Trainly. Trainly est une idée d'application de divertissement pour le groupe SNCF. L'application permet de s'occuper pendant son trajet à bord des TGV, TER ou encore RER.",
    info2: '',
    url: 'https://www.figma.com/file/XiX0o3kczZHsQRob2sgOKa/Application-SNCF?node-id=52%3A2',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'projet-valentin-dedrie-kungfood.jpg',
    title: 'Kung Food - Identité graphique',
    info: "Identité graphique et les maquettes du site réalisées pour l'appplication Kung Food. Kung Food est une application permettant de mieux gérer ses habitudes alimentaires et de contrôler son budget lié à la nourriture.",
    info2: '',
    url: 'https://www.figma.com/file/sygLWRxRkJ92tWNfvAWHOc/Kung-Food?node-id=0%3A1',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'projet-valentin-dedrie-blindify.jpg',
    title: "Blindify - Design de l'application & intégration des maquettes",
    info: "Création des maquettes et intégration de celles-ci pour la web application Blindify. Blindify, comme son nom l'indique, est un jeu de Blindtest utilisant les différentes API de Spotify.",
    info2: '',
    url: 'https://www.figma.com/file/XgSjVEHlJqfoguATSrBb8P/Blindify?node-id=9%3A936',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'projet-valentin-dedrie-dyc.png',
    title: "DYC - Création du logo",
    info: "Création du logo pour le Dunkerque Yachting Club ainsi que l'intégraction de celui-ci sur différents supports de communication : carte de visite, mug, t-shirt, etc...",
    info2: '',
    url: 'https://dunkerqueyachtingclub.fr/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'projet-valentin-dedrie-tropicana.png',
    title: "Tropicana - Création du logo",
    info: "Réalisation du logo sous Illustrator pour le Tropicana Summer Club ainsi que l'intégraction de celui-ci sur différents supports de communication : t-shirt, casquette, short, serviette, etc...",
    info2: '',
    url: 'https://www.le-tropicana.com/',
    repo: '', // if no repo, the button will not show up
  },
  {
    id: nanoid(),
    img: 'projet-valentin-dedrie-aide-moniteur.jpg',
    title: "Aide Moniteur- Réalisation de l'identité graphique",
    info: "Réalisation de l'identité graphique Aide Moniteur pour la base nautique Jean Binard, basée à Gravelines. Intégration de l'identité graphique sur des t-shirts, mugs et casquettes.",
    info2: '',
    url: 'https://www.gravelines-basenautique.fr/',
    repo: '', // if no repo, the button will not show up
  },
];

// CONTACT DATA
export const contactData = {
  cta: "Pour toute demande d'information, discuter de votre projet ou simplement échanger autour d'un café, cliquez sur le bouton ci-dessous. Je vous répondrai dans les plus brefs délais. À très bientôt !",
  btn: 'Contactez-moi !',
  email: 'contact@valentindedrie.fr',
};

// FOOTER DATA
export const footerData = {
  networks: [
    {
      id: nanoid(),
      name: 'twitter',
      url: 'https://twitter.com/pxgun',
    },
    {
      id: nanoid(),
      name: 'instagram',
      url: 'https://www.instagram.com/valentin_dedrie/',
    },
    {
      id: nanoid(),
      name: 'pinterest',
      url: 'https://www.pinterest.fr/agencevalentindedrie/'
    },
    {
      id: nanoid(),
      name: 'linkedin',
      url: 'https://www.linkedin.com/in/valentin-dedrie/',
    },
    {
      id: nanoid(),
      name: 'gitlab',
      url: 'https://gitlab.com/ValentinDedrie',
    },
    {
      id: nanoid(),
      name: 'pagelines',
      url: 'https://colle-a-bois.fr/',
    },
  ],
};

// Github start/fork buttons
export const githubButtons = {
  isEnabled: false, // set to false to disable the GitHub stars/fork buttons
};
